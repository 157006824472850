import axios from 'axios'


//Constants
export const route = process.env.VUE_APP_ROUTE
export const API_ROUTE = process.env.VUE_APP_API_ROUTE
export const MISALUD_ROUTE = process.env.VUE_APP_MISALUD_ROUTE
export const API_ROOT_ROUTE = process.env.VUE_APP_API_ROOT_ROUTE

const HTTP = axios.create({
    baseURL: API_ROUTE,
})

HTTP.CancelToken = axios.CancelToken
HTTP.isCancel = axios.isCancel

HTTP.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('misalud')

        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`
        }

        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)

export default HTTP