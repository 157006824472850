<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="col-12">
                            <div class="card">
                                <!-- Nav tabs -->
                                <ul class="nav nav-tabs profile-tab" role="tablist">
                                    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#profile"
                                            role="tab">Perfil</a>
                                    </li>
                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#files"
                                            role="tab">Archivos</a>
                                    </li>
                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#comments"
                                            role="tab">Comentarios</a>
                                    </li>
                                </ul>
                                <!-- Tab panes -->
                                <div class="tab-content">
                                    <div class="tab-pane active" id="profile" role="tabpanel">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3 col-xs-6 b-r"> <strong>Nombre completo</strong>
                                                    <br>
                                                    <p class="text-muted">{{ data.nombre + ' ' + data.apellido }}</p>
                                                </div>
                                                <div class="col-md-3 col-xs-6 b-r"> <strong>Celular</strong>
                                                    <br>
                                                    <p class="text-muted">{{ data.celular }}</p>
                                                </div>
                                                <div class="col-md-3 col-xs-6 b-r"> <strong>Email</strong>
                                                    <br>
                                                    <p class="text-muted">{{ data.email }}</p>
                                                </div>
                                                <div class="col-md-3 col-xs-6"> <strong>Domicilio</strong>
                                                    <br>
                                                    <p class="text-muted">{{ data.domicilio }}</p>
                                                </div>
                                            </div>
                                            <hr>
                                            <p class="m-t-30">Los siguientes datos son cofidenciales:</p>
                                            <h4 class="font-medium m-t-30">Datos estudio médico</h4>
                                            <hr>
                                            <ul>
                                                <li><strong>Nombre:</strong> {{ data.nombre_estudio }}</li>
                                                <li><strong>Fecha:</strong> {{ data.fecha }}</li>
                                                <li><strong>Hora:</strong> {{ data.hora }}</li>
                                                <li><strong>Lugar:</strong> {{ data.lugar }}</li>
                                                <li v-if="data.motivo"><strong>Motivo:</strong> {{ data.motivo }}</li>
                                                <li v-if="data.tags"><strong>Tags:</strong> {{ data.tags }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!--second tab-->
                                    <div class="tab-pane" id="files" role="tabpanel">
                                        <div class="card-body">
                                            <div class="profiletimeline border-left-0">
                                                <div class="sl-item">
                                                    <div class="sl-right">
                                                        <div>
                                                            <h3 class="d-inline">Documentos adjuntos</h3> <span
                                                                class="sl-date">({{ imagen.length }})</span>
                                                            <div class="row mt-3">
                                                                <div v-for="(doc) in imagen" :key="doc.id" class="col-12 m-b-20">
                                                                    <ul v-if="(doc.url.split('.')[doc.url.split('.').length - 1] == 'jpg' || doc.url.split('.')[doc.url.split('.').length - 1] == 'jpeg' || doc.url.split('.')[doc.url.split('.').length - 1] == 'png')">
                                                                        <li>
                                                                            <a :href="route + doc.url" target="_blank" rel="noopener noreferrer">Documento {{ doc.id }}</a> 
                                                                        </li>
                                                                    </ul>
                                                                    <img v-if="(doc.url.split('.')[doc.url.split('.').length - 1] == 'jpg' || doc.url.split('.')[doc.url.split('.').length - 1] == 'jpeg' || doc.url.split('.')[doc.url.split('.').length - 1] == 'png')"
                                                                        :src="route + doc.url" :alt="data.nombre_estudio"
                                                                        class="img-responsive radius w-25 ml-5">
                                                                    <ul v-else>
                                                                        <li>
                                                                            <a :href="route + doc.url" target="_blank" rel="noopener noreferrer">Documento {{ doc.id }}</a> 
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="comments" role="tabpanel">
                                        <div class="card-body">
                                            <form class="form-horizontal form-material" method="POST" @submit.prevent="send(comentario)">
                                                <h3 v-if="comentarios.length > 0">Comentarios anteriores</h3>
                                                <div v-if="comentarios.length > 0" class="profiletimeline border-left-0">
                                                    <div v-for="(comment) in comentarios" :key="comment.id" class="sl-item">
                                                        <div class="sl-left"> <img src="/assets/images/users/user.png" alt="user" class="img-circle"> </div>
                                                        <div class="sl-right">
                                                            <div>
                                                                <a href="#" class="link">{{ comment.nombre }}</a> <span class="sl-date">{{ comment.created_at }}</span>
                                                                <p class="m-t-10">{{ comment.descripcion }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                                <h3 class="mt-5">Dejar un comentario</h3>
                                                <div class="form-group">
                                                    <label class="col-md-12 mb-0"></label>
                                                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4">
                                                        <input type="text" v-model="comentario.nombre" class="form-control" :disabled="disabled" placeholder="Nombre autor" required>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4 mt-3">
                                                        <textarea v-model="comentario.descripcion" class="form-control" cols="30" rows="5" :disabled="disabled" placeholder="Escriba aquí sus observaciones.." required></textarea>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="col-sm-12 d-flex d-inline">
                                                        <button v-if="action != 'see'" class="btn btn-success text-white ml-2" :disabled="submit_disabled">{{ submit_button }}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { emptyFields, timestampToDate } from '@/helpers/functions'
import { API_ROOT_ROUTE } from "../../environment"
import { verEstudio } from "./../../services/estudio.service";
import { getArchivos } from "./../../services/archivo.service";
import { getComentarios, crearComentario } from "./../../services/comentario.service";

export default {
    name: "resumen-estudio-component",
    props: {
        token: String
    },
    data() {
        return {
            data: {
                id: '',
                users_persona_id: '',
                nombre: '',
                apellido: '',
                documento: '',
                domicilio: '',
                email: '',
                celular: '',
                nombre_estudio: '',
                categoria_id: '',
                tags: '',
                selected_tags: '',
                lugar: '',
                motivo: '',
                fecha: '',
                hora: '',
                archivos: [],
            },
            comentario: {
                estudio_id: '',
                nombre: '',
                descripcion: ''
            },
            route: API_ROOT_ROUTE,
            show: false,
            imagen: '',
            url: '',
            tags: [],
            comentarios: [],
            disabled: (this.action == 'see') ? true : false,
            submit_button: '',
            submit_disabled: ''
        }
    },
    mounted() {
        this.submit_button = 'Enviar comentario';
        this.submit_disabled = false;
    },
    created() {
        this.getEstudioByToken(this.token)
    },
    methods: {
        async getEstudioByToken(token) {
            const response = await verEstudio(token);

            if (response.data.errors) {
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.id = response.data.id
                this.data.users_persona_id = response.data.users_persona_id
                this.data.nombre = response.data.nombre
                this.data.apellido = response.data.apellido
                this.data.nombre_estudio = response.data.nombre_estudio
                this.data.categoria_id = response.data.categoria_id
                this.data.celular = response.data.celular
                this.data.documento = response.data.documento
                this.data.domicilio = response.data.domicilio
                this.data.email = response.data.email
                this.tags = response.data.tags.split(",")
                this.data.fecha = response.data.fecha
                this.data.hora = response.data.hora
                this.data.lugar = response.data.lugar
                this.data.motivo = response.data.motivo

                this.getArchivos(response.data.id);
                this.getComentarios(response.data.id);
                this.comentario.estudio_id = response.data.id;
            }
        },
        async getArchivos(id) {
            const response = await getArchivos(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.imagen = response.data
            }
        },
        async getComentarios(estudio_id) {
            const response = await getComentarios(estudio_id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.comentarios = response.data
                this.comentarios.forEach(comentario => {
                    comentario.created_at = timestampToDate(comentario.created_at);
                })
            }
        },
        async send(comentario) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            const response = await crearComentario(comentario);
        
            this.submit_button = 'Enviar comentario';
            this.submit_disabled = false;
            
            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data = emptyFields(comentario);
                this.$toast.success("¡Comentario enviado con éxito!")
                this.comentarios.push(response.data);
                this.comentarios.sort()
            }
            
        }
    }
}
</script>