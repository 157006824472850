<template>
    <div class="hori-timeline" dir="ltr">
        <ul v-if="eventos.length > 0" class="list-inline events">
            <li v-for="(evento) in eventos" :key="evento.id" class="list-inline-item event-list">
                <div class="px-4">
                    <div class="event-date bg-soft-primary text-primary mt-3">{{ evento.fecha }}</div>
                    <h5 class="font-size-16 mt-3">{{ evento.nombre }}</h5>
                    <p class="text-muted">{{ evento.descripcion }}</p>
                    <div>
                        <router-link :to="{name:'ver-evento', params: {action: 'see', id: evento.id}}" class="btn btn-primary btn-sm">Ver evento</router-link>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p><i class="mdi mdi-alert-outline"></i> No se encontraron eventos.</p> 
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <router-link class="btn btn-secondary" :to="{name: 'home'}"><i class="mdi mdi-keyboard-backspace"></i> Volver</router-link>
        </div>
    </div>
</template>
<script>
import { getStringDate } from "@/helpers/functions"
import { getEventos } from "./../../services/evento.service";
  
  export default {
    name: "listado-eventos-component",
    props: {
        users_persona_id: Number
    },
    data() {
        return {
            data: {
                
            },
            eventos: []
        }
    },
    mounted() {
        
    },
    created() {
        this.getEventos(this.users_persona_id)
    },
    watch: {
        users_persona_id: function () {
            this.getEventos(this.users_persona_id)
        }
    },
    methods: {
        async getEventos(users_persona_id){
            const response = await getEventos(users_persona_id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.eventos = response.data;

                this.eventos.forEach(evento => {
                    evento.fecha = getStringDate(evento.fecha)
                })
            }
        },
    }
  }
  </script>
  <style>
  .hori-timeline .events {
      border-top: 3px solid #e9ecef;
  }
  .hori-timeline .events .event-list {
      display: block;
      position: relative;
      text-align: center;
      padding-top: 70px;
      margin-right: 0;
  }
  .hori-timeline .events .event-list:before {
      content: "";
      position: absolute;
      height: 36px;
      border-right: 2px dashed #dee2e6;
      top: 0;
  }
  .hori-timeline .events .event-list .event-date {
      position: absolute;
      top: 38px;
      left: 0;
      right: 0;
      width: 75px;
      margin: 0 auto;
      border-radius: 4px;
      padding: 2px 4px;
  }
  @media (min-width: 1140px) {
      .hori-timeline .events .event-list {
          display: inline-block;
          width: 24%;
          padding-top: 45px;
      }
      .hori-timeline .events .event-list .event-date {
          top: -12px;
      }
  }
  .bg-soft-primary {
      background-color: rgba(64,144,203,.3)!important;
  }
  .bg-soft-success {
      background-color: rgba(71,189,154,.3)!important;
  }
  .bg-soft-danger {
      background-color: rgba(231,76,94,.3)!important;
  }
  .bg-soft-warning {
      background-color: rgba(249,213,112,.3)!important;
  }
  
  </style>