import HTTP from "../environment";

export const getCategorias = () => {
    return new Promise(resolve => {
        HTTP
            .get('categorias')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getCategoria = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('categoria/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const deleteCategoria = (categoria) => {
    return new Promise(resolve => {
        HTTP
            .delete('categoria/' + categoria.id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateCategoria = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('categoria/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const createCategoria = (formData) => {
    return new Promise(resolve => {
        HTTP
            .post('categoria', formData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const updateCategoria = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('categoria/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}