<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-12 col-md-9">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-lg-6 text-center">
                                <img class="img-fluid pt-5" src="/assets/images/logo MiSalud.png" alt=""
                                    srcset="">
                            </div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h2 text-gray-800" style="margin-bottom: 5rem !important;">
                                            ¡Bienvenido!</h1>
                                    </div>
                                    <p>Por favor, ingrese su e-mail y contraseña:</p>
                                    <form class="user" method="POST" @submit.prevent="send(data)">
                                        <div class="form-group">
                                            <input id="email" type="email"
                                                class="form-control" v-model="data.email" required autocomplete="email"
                                                placeholder="E-mail" autofocus>
                                            <span class="invalid-feedback" role="alert">
                                                <strong></strong>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <input id="password" type="password"
                                                class="form-control"
                                                v-model="data.password" placeholder="Contraseña" required
                                                autocomplete="current-password">
                                            <span class="invalid-feedback" role="alert">
                                                <strong></strong>
                                            </span>
                                        </div>
                                        <button type="submit" class="btn btn-success text-white btn-user btn-block"
                                            style="background-color: #004aad !important; border-color: #004aad !important;" :disabled="submit_disabled">
                                            {{ submit_button }}
                                        </button>
                                    </form>
                                    <hr>
                                    <p class="text-center">
                                        <router-link class="text-primary" :to="{name: 'registro'}">Registrarse</router-link>
                                    </p>
                                    <!-- <p class="text-center"><a href="#">¿Olvidaste tu
                                            contraseña?</a></p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { login, setToken, setUserData, removeToken } from "./../../services/auth.service";

export default {
    name: "login-component",
    props: {

    },
    data() {
        return {
            data: {
                email: '',
                password: ''
            },
            submit_button: '',
            submit_disabled: '',
            message: ''
        }
    },
    mounted() {
        this.submit_button = 'Ingresar';
        this.submit_disabled = false;
    },
    created() {
        
    },
    methods: {
        async send(data) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            let formData = new FormData();
            formData.append("email", data.email);
            formData.append("password", data.password);

            if (data.email != '' && data.password != '') {
                const response = await login(formData);
                
                this.submit_button = 'Ingresar';
                this.submit_disabled = false;
                
                removeToken();

                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.message)
                } else {                    
                    setToken(response.token);
                    setUserData(response.data);
                    this.$toast.success("¡Bienvenido!")
                    this.$router.push({name: "home"})
                }
            }
        }
    }
}
</script>