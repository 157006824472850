import HTTP from "../environment";

export const getFichas = () => {
    return new Promise(resolve => {
        HTTP
            .get('fichas')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getFicha = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('user/ficha/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const deleteFicha = (Ficha) => {
    return new Promise(resolve => {
        HTTP
            .delete('ficha/' + Ficha.id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateFicha = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('ficha/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const crearFicha = (data) => {
    return new Promise(resolve => {
        HTTP
            .post('user/ficha', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const editarFicha = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('user/ficha/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}