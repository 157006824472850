import HTTP from "../environment";

export const getTurnos = (users_persona_id) => {
    return new Promise(resolve => {
        HTTP
            .get('turnos/' + users_persona_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getTurno = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('turno/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const eliminarTurno = (id) => {
    return new Promise(resolve => {
        HTTP
            .delete('turno/' + id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateTurno = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('turno/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const crearTurno = (formData) => {
    return new Promise(resolve => {
        HTTP
            .post('turno', formData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const editarTurno = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('turno/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}