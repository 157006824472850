import HTTP from "../environment";

export const getArchivos = (estudio_id) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/archivos/' + estudio_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getArchivo = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/archivo/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const deleteArchivo = (archivo) => {
    return new Promise(resolve => {
        HTTP
            .delete('archivo/' + archivo.id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateArchivo = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/archivo/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const createArchivo = (formData) => {
    return new Promise(resolve => {
        HTTP
            .post('archivo', formData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const updateArchivo = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('archivo/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}