import { createRouter, createWebHistory } from 'vue-router'
import { middleware, removeToken } from '../services/auth.service'
import Registro from '../components/auth/Registro.vue'
import Login from '../components/auth/Login.vue'
import Home from '../components/pages/Home.vue'
import Perfiles from '../components/perfiles/Perfiles.vue'
import PerfilForm from '../components/perfiles/PerfilForm.vue'
import FichaForm from '../components/ficha/FichaForm.vue'
import Fichas from '../components/ficha/Fichas.vue'
import EstudioForm from '../components/estudios/EstudioForm.vue'
import Estudios from '../components/estudios/Estudios.vue'
import EstudioCompartido from '../components/estudios/EstudioCompartido.vue'
import ResumenEstudio from '../components/estudios/ResumenEstudio.vue'
import EventoForm from '../components/eventos/EventoForm.vue'
import Eventos from '../components/eventos/Eventos.vue'
import TurnoForm from '../components/turnos/TurnoForm.vue'
import Turnos from '../components/turnos/Turnos.vue'
import { route } from "../environment"

const routes = [
  {
    path: "",
    name: 'inicio',
    component: Login,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        {
          name: 'Login',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: "/registro",
    name: 'registro',
    component: Registro,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        {
          name: 'Registro',
          redirect: 'registro',
          current: true
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        {
          name: 'Login',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Login,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        {
          name: 'logout',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/perfiles',
    name: 'perfiles',
    component: Perfiles,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/perfil/:action',
    name: 'agregar-perfil',
    component: PerfilForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Agregar perfil',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/perfil/:action/:id',
    name: 'editar-perfil',
    component: PerfilForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Editar perfil',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/perfil/:action/:id',
    name: 'ver-perfil',
    component: PerfilForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Ver perfil',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/fichas',
    name: 'fichas',
    component: Fichas,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Fichas',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/ficha/:action/:propietario_id',
    name: 'agregar-ficha',
    component: FichaForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Nueva ficha',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/ficha/:action/:propietario_id/:id',
    name: 'editar-ficha',
    component: FichaForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Editar ficha',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/ficha/:action/:propietario_id/:id',
    name: 'ver-ficha',
    component: FichaForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Perfiles',
          redirect: 'perfiles',
          current: false
        },
        {
          name: 'Ver ficha',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/estudios',
    name: 'estudios',
    component: Estudios,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Estudios',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/estudio/:action/:users_persona_id?/:categoria_id?',
    name: 'agregar-estudio',
    component: EstudioForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Estudios',
          redirect: 'estudios',
          current: false
        },
        {
          name: 'Agregar estudio',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/estudio/:action/:id',
    name: 'editar-estudio',
    component: EstudioForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Estudio',
          redirect: 'estudios',
          current: false
        },
        {
          name: 'Editar estudio',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/estudio/:action/:id',
    name: 'ver-estudio',
    component: EstudioForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Estudios',
          redirect: 'estudios',
          current: false
        },
        {
          name: 'Ver estudio',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/estudios/:users_persona_id/:categoria_id',
    name: 'ver-estudios',
    component: Estudios,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Estudios',
          redirect: 'estudios',
          current: false
        },
        {
          name: 'Ver estudios',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/estudio/:action/:id',
    name: 'compartir-estudio',
    component: EstudioCompartido,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Estudios',
          redirect: 'estudios',
          current: false
        },
        {
          name: 'Compartir estudio',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: "/visualizar-estudio/:token",
    name: 'visualizar-estudio',
    component: ResumenEstudio,
    props: true,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        {
          name: 'ResumenEstudio',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: Eventos,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Eventos',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/evento/:action',
    name: 'agregar-evento',
    component: EventoForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Eventos',
          redirect: 'eventos',
          current: false
        },
        {
          name: 'Agregar evento',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/evento/:action/:id',
    name: 'editar-evento',
    component: EventoForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Eventos',
          redirect: 'eventos',
          current: false
        },
        {
          name: 'Editar evento',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/evento/:action/:id',
    name: 'ver-evento',
    component: EventoForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Eventos',
          redirect: 'eventos',
          current: false
        },
        {
          name: 'Ver evento',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/turnos',
    name: 'turnos',
    component: Turnos,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Turnos',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/turno/:action/:users_persona_id?',
    name: 'agregar-turno',
    component: TurnoForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Turnos',
          redirect: 'turnos',
          current: false
        },
        {
          name: 'Agregar turno',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/turno/:action/:id',
    name: 'editar-turno',
    component: TurnoForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Turno',
          redirect: 'turnos',
          current: false
        },
        {
          name: 'Editar turno',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/turno/:action/:id',
    name: 'ver-turno',
    component: TurnoForm,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Turnos',
          redirect: 'turnos',
          current: false
        },
        {
          name: 'Ver turno',
          redirect: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/turnos/:users_persona_id',
    name: 'ver-turnos',
    component: Turnos,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        {
          name: 'Home',
          redirect: 'home',
          current: false
        },
        {
          name: 'Turnos',
          redirect: 'turnos',
          current: false
        },
        {
          name: 'Ver turnos',
          redirect: '#',
          current: true
        }
      ]
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if ((to.meta.requiresAuth && !middleware())) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    return {
      path: '/login',
      // save the location we were at to come back later
      // query: { redirect: to.fullPath },
    };
  } else {
    if(to.path == route + "login" && middleware()){
      removeToken();
      window.location = route;
    }
  }
})

export default router