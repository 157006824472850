<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div v-if="!share" class="card-body">
                        <h3>Usted está por compartir un estudio:</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae ipsum eaque praesentium quo, odio at deleniti. Cum vitae repudiandae, voluptatum neque itaque odio cumque ex laborum ut, dolorum molestiae accusantium esse necessitatibus ab praesentium culpa magnam ipsam nobis aut obcaecati alias animi? Est nisi delectus porro culpa ratione sit totam quidem perferendis consequatur exercitationem, dolore eligendi, ipsa natus optio aspernatur, enim cupiditate sapiente expedita. Voluptatem autem neque quidem natus, cupiditate, rerum reiciendis suscipit voluptatibus harum maxime qui, voluptate assumenda saepe blanditiis quia quo dolor distinctio esse. Nemo culpa voluptatibus provident sunt sapiente alias ad dignissimos tenetur molestias! Fuga aliquam at neque laudantium quae, totam et sit consequuntur magnam mollitia nisi obcaecati, quis libero dicta eveniet cumque culpa ipsam quibusdam minus doloribus beatae amet inventore! Rem quisquam asperiores mollitia, provident alias nostrum dolor veritatis. Labore fugit vitae odit veniam sapiente expedita asperiores quos natus delectus dolorum sunt eligendi laborum cupiditate harum laudantium tempore maxime, ad sequi eveniet illo numquam dolores, reiciendis magni dicta! Reiciendis, quae? Quae repellendus est quos voluptate! Repellat eligendi porro, non nam ex laboriosam, blanditiis, expedita nulla quos corporis hic temporibus ipsam? Voluptatibus perspiciatis consectetur voluptatum labore molestias perferendis quos voluptate, necessitatibus repudiandae quisquam quas laudantium accusantium qui, nesciunt debitis minus provident. Iusto laudantium cumque voluptates aliquam iure reiciendis veritatis autem sed soluta deserunt rerum cum illum tempore aperiam quisquam ratione perferendis, consequatur voluptatum at adipisci et ipsam voluptatem velit dolor. Alias illum laborum dolor repudiandae reprehenderit necessitatibus sint quam perspiciatis distinctio, consequatur ducimus odio. Architecto, nostrum harum!</p>
                        <hr>
                        <h3>Estudio: </h3>{{ data.nombre }}
                        <ul>
                            <li><strong>Fecha:</strong> {{ data.fecha }}</li>
                            <li><strong>Hora:</strong> {{ data.hora }}</li>
                            <li><strong>Lugar:</strong> {{ data.lugar }}</li>
                            <li v-if="data.motivo"><strong>Motivo:</strong> {{ data.motivo }}</li>
                            <li v-if="data.tags"><strong>Tags:</strong> {{ data.tags }}</li>
                        </ul>
                        <p>Archivos a compartir: </p>
                        <ul class="ml-3">
                            <li v-for="image in imagen" :key="image.id" style="width: 18rem;">
                                <a class="card-img-top" :href="api_route + image.url" target="_blank">{{ image.url.split("/")[image.url.split("/").length - 1] }}</a>
                            </li>
                        </ul>
                        <div class="form-group">
                            <div class="col-sm-12 d-flex d-inline">
                                <router-link :to="{name: 'estudios'}" class="btn btn-secondary mx-auto mx-md-0 text-white text-decoration-none">Volver</router-link>
                                <button v-if="action == 'share'" class="btn btn-success text-white ml-2" :disabled="submit_disabled" @click="shareEstudio(data.id)"><i class="mdi mdi-share-variant"></i> {{ submit_button }}</button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card-body text-center">
                        <h3>¡Listo! Su estudio está listo para ser compartido</h3>
                        <p>Puedes mostrar el código QR que aparece a continuación para que tu estudio pueda ser visualizado por la persona que desees.</p>
                        <p>Si tienes algún inconveniente para abrir el estudio desde el escaneo del código QR, podrás compartir el siguiente enlace para dar acceso quien quieras: <a :href="route + 'visualizar-estudio/' + token" target="_blank">Click aquí</a></p>
                        <QRCodeVue3 class="col-12" :value="route + 'visualizar-estudio/' + token" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
import QRCodeVue3 from 'qrcode-vue3';
import { API_ROOT_ROUTE, MISALUD_ROUTE } from "../../environment"
import { getEstudio, shareEstudio } from "./../../services/estudio.service";
import { getArchivos } from "./../../services/archivo.service";
  
export default {
    name: "estudio-compartido-component",
    props: {
        action: String,
        id: Number
    },
    data() {
        return {
            data: {
                id: '',
                users_persona_id: '',
                nombre: '',
                categoria_id: '',
                tags: '',
                selected_tags: '',
                lugar: '',
                motivo: '',
                fecha: '',
                hora: '',
                archivos: []
            },
            route: MISALUD_ROUTE,
            api_route: API_ROOT_ROUTE,
            share: false,
            token: '',
            imagen: '',
            submit_button: '',
            submit_disabled: ''
        }
    },
    mounted() {
        this.submit_button = 'Compartir';
        this.submit_disabled = false;
    },
    created() {
        if(this.id != undefined && this.id != ''){
            this.getEstudio(this.id)
        }
    },
    components: {
        QRCodeVue3
    },
    watch: {
        action: function () {
            this.disabled = (this.action == 'see') ? true : false
        }
    },
    methods: {
        async getEstudio(id){
            const response = await getEstudio(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.id = response.data.id
                this.data.users_persona_id = response.data.users_persona_id
                this.data.nombre = response.data.nombre
                this.data.categoria_id = response.data.categoria_id
                this.tags = response.data.tags.split(",")
                this.data.fecha = response.data.fecha
                this.data.hora = response.data.hora
                this.data.lugar = response.data.lugar
                this.data.motivo = response.data.motivo

                this.getArchivos(response.data.id);
            }
        },
        async getArchivos(id) {
            const response = await getArchivos(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.imagen = response.data
            }
        },
        async shareEstudio(id) {
            const response = await shareEstudio(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.token = response.data;
                this.share = true;
            }
        }
    }
}
</script>