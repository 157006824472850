<template>
    <div class="card-deck">
        <div class="row">
            <div v-for="(categoria) in categorias" :key="categoria.id" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mt-3">
                <router-link :to="{name: 'ver-estudios', params: {users_persona_id: users_persona_id, categoria_id: categoria.id}}">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                    <i class="mdi mdi-folder fa-6x"></i>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                                    <h5 class="card-title">{{ categoria.nombre }}</h5>
                                    <p class="card-text">
                                        {{ categoria.descripcion }}
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <!-- <router-link class="btn btn-info text-white mt-3" :to="{name:'ver-categoria', params: {action: 'see', id: categoria.id}}"><i class="mdi mdi-eye"></i> Ver categoria</router-link>
                                    <router-link v-if="categoria.ficha_medica_id == null" :to="{name:'agregar-ficha', params: {action: 'add', propietario_id: categoria.id}}" class="btn btn-success ml-md-2 ml-xs-0 text-white mt-3"><i class="mdi mdi-file-document-box"></i> Agregar ficha médica</router-link>
                                    <router-link v-else :to="{name:'ver-ficha', params: {action: 'see', propietario_id: categoria.id, id: categoria.ficha_medica_id}}" class="btn btn-secondary ml-md-2 ml-xs-0 text-white mt-3"><i class="mdi mdi-eye"></i> Ver ficha médica</router-link> -->
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <small class="text-muted">Última modificación {{ categoria.fecha_modificacion }}</small>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
  </template>
<script>
import { getCategorias } from "./../../services/categoria.service";
  
  export default {
    name: "listado-categorias-component",
    props: {
        users_persona_id: Number
    },
    data() {
        return {
            data: {
                
            },
            categorias: []
        }
    },
    mounted() {
        
    },
    created() {
        this.getCategorias();
    },
    methods: {
        async getCategorias(){
            const response = await getCategorias();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.categorias = response.data;
            }
        }
    }
  }
  </script>