import HTTP from "../environment";

export const getUsers = () => {
    return new Promise(resolve => {
        HTTP
            .get('users')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getUser = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('user/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const deleteUser = (user) => {
    return new Promise(resolve => {
        HTTP
            .delete('user/' + user.id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const changeStatus = (id, status) => {
    return new Promise(resolve => {
        HTTP
            .put('user/status/' + id, {status: status})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                        errors: true,
                        message: error.message,
                        status: error.code
                    })
            })
    })
}

export const changePassword = (id, password) => {
    return new Promise(resolve => {
        HTTP
            .put('cambiar-password/' + id, {
                password: password
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    })
}

export const validateEmail = (email) => {
    return new Promise(resolve => {
        HTTP
            .get('user/email/' + email)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validatePassword = (password, password2) => {
    if (password == password2) {
        return true
    } else {
        return false
    }
}

export const register = (formData) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return new Promise(resolve => {
        HTTP
            .post('auth/register', formData, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const updateUser = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('user/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const getUserData = () => {
    if(localStorage.getItem('misalud') !== null && localStorage.getItem('user') !== null){
        return JSON.parse(localStorage.getItem('user'));
    }else{
        return null;
    }
}


export const crearPerfil = (data) => {
    return new Promise(resolve => {
        HTTP
            .post('user/perfil', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                return error
            });
    });
}

export const editarPerfil = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('user/perfil/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                return error
            });
    });
}

export const getPerfiles = () => {
    return new Promise(resolve => {
        HTTP
            .get('user/perfiles')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getPerfil = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('user/perfil/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const eliminarPerfil = (id) => {
    return new Promise(resolve => {
        HTTP
            .delete('user/perfil/' + id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}