<template>
    <div class="card-deck">
        <div v-for="(perfil) in perfiles" :key="perfil.id" class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3">
                        <img class="card-img-top" :src="'/assets/images/users/' + perfil.imagen + '.jpeg'" alt="Card image cap">
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-9">
                        <h5 class="card-title">{{ perfil.nombre + ' ' + perfil.apellido}}</h5>
                        <p class="card-text">
                            <ul>
                                <li><strong>DNI:</strong> {{ perfil.documento }}</li>
                                <li><strong>Domicilio:</strong> {{ perfil.domicilio }}</li>
                                <li><strong>Tipo sangre:</strong> {{ perfil.tipo_sangre }}</li>
                                <li v-if="perfil.peso"><strong>Peso:</strong> {{ perfil.peso }}kg</li>
                                <li v-if="perfil.talla"><strong>Talla:</strong> {{ perfil.talla }}m</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-info text-white mt-3" :to="{name:'ver-perfil', params: {action: 'see', id: perfil.id}}"><i class="mdi mdi-eye"></i> Ver perfil</router-link>
                        <router-link v-if="perfil.ficha_medica_id == null" :to="{name:'agregar-ficha', params: {action: 'add', propietario_id: perfil.id}}" class="btn btn-success ml-md-2 ml-xs-0 text-white mt-3"><i class="mdi mdi-file-document-box"></i> Agregar ficha médica</router-link>
                        <router-link v-else :to="{name:'ver-ficha', params: {action: 'see', propietario_id: perfil.id, id: perfil.ficha_medica_id}}" class="btn btn-secondary ml-md-2 ml-xs-0 text-white mt-3"><i class="mdi mdi-eye"></i> Ver ficha médica</router-link>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <small class="text-muted">Última modificación {{ perfil.fecha_modificacion }}</small>
            </div>
        </div>
    </div>
</template>
<script>
import { getRandomNumber, timestampToDate } from '@/helpers/functions'
import { getPerfiles } from "./../../services/user.service";

export default {
    name: "listado-perfiles-component",
    props: {

    },
    data() {
        return {
            data: {

            },
            perfiles: []
        }
    },
    mounted() {

    },
    created() {
        this.getPerfiles();
    },
    methods: {
        async getPerfiles(){
            const response = await getPerfiles();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                response.data.forEach(perfil => {
                    this.perfiles.push({
                        id: perfil.id,
                        nombre: perfil.nombre,
                        apellido: perfil.apellido,
                        domicilio: perfil.domicilio,
                        celular: perfil.celular,
                        telefono: perfil.telefono,
                        fecha_nacimiento: perfil.fecha_nacimiento,
                        documento: perfil.documento,
                        fecha_modificacion: timestampToDate(perfil.updated_at),
                        imagen: getRandomNumber(1, 5),
                        ficha_medica_id: perfil.ficha_medica_id,
                        tipo_sangre: perfil.tipo_sangre,
                        peso: perfil.peso,
                        talla: perfil.talla
                    });
                });
                this.perfiles.sort();
            }
        },
    }
}
</script>