<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Ficha</h4>
                        <router-link :to="{name: 'agregar-ficha'}"
                            class="btn btn-success mx-auto mx-md-0 text-white mb-3"><i class="mdi mdi-account-plus"></i> Agregar ficha</router-link>
                        <ListadoFichas></ListadoFichas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ListadoFichas from './ListadoFichas.vue';

export default {
    name: "fichas-component",
    props: {

    },
    components: {
        ListadoFichas
    },
    data() {
        return {
            data: {

            }
        }
    },
    mounted() {

    },
    created() {

    },
    methods: {

    }
}
</script>