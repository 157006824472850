<template>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12">
                <router-link v-if="action == 'see'" :to="{name:'editar-ficha', params: {action: 'edit', propietario_id: propietario_id, id: id}}" class="btn btn-secondary text-white"><i class="mdi mdi-table-edit"></i> Editar ficha médica</router-link>
                <button v-if="action == 'see'" type="button" class="btn btn-danger text-white ml-2 float-right"><i class="mdi mdi-delete"></i> Eliminar ficha</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-material" method="POST" @submit.prevent="send(data)">
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Tipo de sangre</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <select class="form-control pl-0 form-control-line" v-model="data.tipo_sangre" :disabled="disabled" required>
                                        <option value="">Seleccione un tipo de sangre</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Antecedente personal</label>
                                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4">
                                    <textarea v-model="data.antecedente_personal" class="form-control" cols="30" rows="5" :disabled="disabled"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Antecedente familiar</label>
                                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4">
                                    <textarea v-model="data.antecedente_familiar" class="form-control" cols="30" rows="5" :disabled="disabled"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Alergia</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <textarea v-model="data.alergia" class="form-control" cols="30" rows="5" :disabled="disabled"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Esquema vacunatorio</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <select class="form-control pl-0 form-control-line" v-model="data.vacunas" :disabled="disabled" required>
                                        <option value="">Seleccione</option>
                                        <option value="completo">Completo</option>
                                        <option value="incompleto">Incompleto</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Peso</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="number" placeholder="80kg" step="0.01" min="0" class="form-control pl-0 form-control-line" v-model="data.peso" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Talla</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="number" placeholder="1.80m" step="0.01" min="0" class="form-control pl-0 form-control-line" v-model="data.talla" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 d-flex d-inline">
                                    <router-link :to="{name: 'perfiles'}" class="btn btn-secondary mx-auto mx-md-0 text-white text-decoration-none">Volver</router-link>
                                    <button v-if="action != 'see'" class="btn btn-success text-white ml-2" :disabled="submit_disabled">{{ submit_button }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { emptyFields } from '@/helpers/functions'
import { crearFicha, getFicha, editarFicha } from "./../../services/ficha.service";
  
  export default {
    name: "ficha-form-component",
    props: {
        id: String,
        propietario_id: String,
        action: String
    },
    data() {
        return {
            data: {
                id: '',
                persona_id: this.propietario_id,
                users_persona_id: '',
                tipo_sangre: '',
                antecedente_personal: '',
                antecedente_familiar: '',
                alergia: '',
                vacunas: '',
                peso: 0,
                talla: 0
            },
            disabled: (this.action == 'see') ? true : false,
            submit_button: '',
            submit_disabled: ''
        }
    },
    mounted() {
        this.submit_button = 'Guardar';
        this.submit_disabled = false;
    },
    created() {
        if(this.action != 'add'){
            this.getFicha(this.id);
        }
    },
    watch: {
        action: function () {
            this.disabled = (this.action == 'see') ? true : false
        }
    },
    methods: {
        async getFicha(id){
            const response = await getFicha(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.id = response.data.id
                this.data.users_persona_id = response.data.users_persona_id
                this.data.tipo_sangre = response.data.tipo_sangre
                this.data.antecedente_personal = response.data.antecedente_personal
                this.data.antecedente_familiar = response.data.antecedente_familiar
                this.data.alergia = response.data.alergia
                this.data.vacunas = response.data.vacunas
                this.data.peso = response.data.peso
                this.data.talla = response.data.talla
            }
        },
        async send(data) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(this.id == '' || this.id == undefined){
                const response = await crearFicha(data);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.data = emptyFields(data);
                    this.$toast.success("¡Ficha creada con éxito!")
                    this.$router.push({name: "perfiles"})
                }
            } else {
                const response = await editarFicha(data, this.id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Ficha editada con éxito!")
                }
            }
            
        }
    }
  }
  </script>