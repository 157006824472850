<template>
    <div class="block">
        <div class="row" v-if="estudios.length > 0">
            <div v-for="(estudio) in estudios" :key="estudio.id" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mt-3">
                <router-link :to="{name: 'ver-estudio', params: {action: 'see', id: estudio.id}}">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-4 col-xl-3 col-lg-3">
                                    <i class="mdi mdi-flask-outline fa-6x"></i>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-8 col-xl-9 col-lg-9">
                                    <h5 class="card-title">{{ estudio.nombre }}</h5>
                                    <p class="card-text">
                                        <ul>
                                            <li><strong>Fecha:</strong> {{ estudio.fecha }}</li>
                                            <li><strong>Hora:</strong> {{ estudio.hora }}</li>
                                            <li><strong>Lugar:</strong> {{ estudio.lugar }}</li>
                                            <li v-if="estudio.motivo"><strong>Motivo:</strong> {{ estudio.motivo }}</li>
                                            <li v-if="estudio.tags"><strong>Tags:</strong> {{ estudio.tags }}</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <router-link :to="{name:'ver-estudio', params: {action: 'see', id: estudio.id}}" class="btn btn-success ml-md-2 ml-xs-0 text-white mt-3"><i class="mdi mdi-eye"></i> Ver estudio</router-link>
                                    <router-link class="btn btn-info text-white ml-md-2 ml-xs-0 mt-3 float-right" :to="{name:'compartir-estudio', params: {action: 'share', id: estudio.id}}"><i class="mdi mdi-share-variant"></i> Compartir estudio</router-link>
                                    <!-- 
                                    <router-link v-else :to="{name:'ver-ficha', params: {action: 'see', propietario_id: estudio.id, id: estudio.ficha_medica_id}}" class="btn btn-secondary ml-md-2 ml-xs-0 text-white mt-3"><i class="mdi mdi-eye"></i> Ver ficha médica</router-link> -->
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <small class="text-muted">Última modificación {{ estudio.fecha_modificacion }}</small>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p><i class="mdi mdi-alert-outline"></i> No se encontraron estudios en esta categoría.</p> 
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12">
            <router-link class="btn btn-secondary" :to="{name: 'estudios'}"><i class="mdi mdi-keyboard-backspace"></i> Volver</router-link>
        </div>
        
    </div>
</template>
<script>
import { getEstudios } from "./../../services/estudio.service";
  
export default {
    name: "listado-estudios-component",
    props: {
        categoria_id: [Number, String],
        users_persona_id: [Number, String]
    },
    data() {
        return {
            data: {
                
            },
            estudios: []
        }
    },
    mounted() {
        
    },
    created() {
        this.getEstudios(this.categoria_id, this.users_persona_id);
    },
    watch: {
        categoria_id: function () {

        },
        users_persona_id: function () {

        }
    },
    methods: {
        async getEstudios(categoria_id, users_persona_id){
            const response = await getEstudios(categoria_id, users_persona_id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.estudios = response.data;
            }
        }
    }
}
</script>