<template>
    <div class="page-breadcrumb">
        <div class="row align-items-center">
            <div class="col-md-6 col-8 align-self-center">
                <h3 class="page-title mb-0 p-0">{{ currentPageName.name }}</h3>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li v-for="(page) in breadcrumb" :key="page.name" :class="(page.current) ? 'breadcrumb-item active' : 'breadcrumb-item'" :aria-current="(page.current) ?? 'page'">
                                <router-link v-if="!page.current" :to="{name: page.redirect}">{{ page.name }}</router-link>
                                <span v-else>{{ page.name }}</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useRoute } from 'vue-router';

export default {
    name: "breadcrumb-component",
    props: {

    },
    data() {
        return {
            data: {
                
            }
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    computed: {
        currentPageName: function (){
            return useRoute().meta.breadcrumb[useRoute().meta.breadcrumb.length - 1]
        },
        breadcrumb: function (){
            return useRoute().meta.breadcrumb
        },
        fullPath: function (){
            return useRoute().fullPath
        }
    },
    methods: {
        
    }
}
</script>