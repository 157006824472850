<template>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12">
                <router-link v-if="action == 'see'" :to="{name:'editar-estudio', params: {action: 'edit', id: id}}" class="btn btn-secondary text-white"><i class="mdi mdi-table-edit"></i> Editar estudio</router-link>
                <button v-if="action == 'see'" type="button" class="btn btn-danger text-white ml-2 float-right" data-toggle="modal" data-target="#exampleModal"><i class="mdi mdi-delete"></i> Eliminar estudio</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-material" method="POST" @submit.prevent="send(data)" enctype="multipart/form-data">
                            <div class="form-group">
                                <label class="col-md-12 mb-0"><strong>Propietario del estudio</strong></label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <select class="form-control pl-0 form-control-line" v-model="data.users_persona_id" :disabled="(action != 'add') ? true : disabled" required>
                                        <option v-for="(persona) in personas" :key="persona.id" :value="persona.id" :selected="(persona_actual == persona.id) ? true : false">{{ (persona_actual == persona.id) ? persona.nombre + ' ' + persona.apellido + '(Yo)': persona.nombre + ' ' + persona.apellido }}</option>
                                    </select>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Nombre del estudio</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <input type="text" placeholder="Ej: Radigrafía pierna derecha" class="form-control pl-0 form-control-line" v-model="data.nombre" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Categoría estudio</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <select class="form-control pl-0 form-control-line" v-model="data.categoria_id" :disabled="disabled" required>
                                        <option value="">Seleccione una categoría</option>
                                        <option v-for="(categoria) in categorias" :key="categoria.id" :value="categoria.id">{{ categoria.nombre }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="!disabled" class="form-group">
                                <label class="col-md-12 mb-0">Etiquetas</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <vue3-tags-input class="form-control" v-model="data.selected_tags" :tags="tags" placeholder="Ej: Pierna Esguince" @on-tags-changed="handleChangeTag" :disabled="disabled"/>
                                </div>
                            </div>
                            <div v-else class="form-group">
                                <label class="col-md-12 mb-0">Etiquetas</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    {{ tags }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Fecha del estudio</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3"> 
                                    <input type="date" class="form-control pl-0 form-control-line" v-model="data.fecha" :disabled="disabled" :max="max" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Hora</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mb-3">
                                    <input type="time" placeholder="456 7890" class="form-control pl-0 form-control-line" v-model="data.hora" :disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Lugar</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mb-3">
                                    <input type="text" placeholder="Ej: Sanatorio 9 de Julio" class="form-control pl-0 form-control-line" v-model="data.lugar" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Motivo</label>
                                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4 mb-3">
                                    <textarea v-model="data.motivo" class="form-control" cols="30" rows="5" :disabled="disabled" placeholder="Ej: Operación de rodilla derecha."></textarea>
                                </div>
                            </div>
                            <div v-if="!show && data.archivos_existentes.length > 0" class="form-group">
                                <div class="col-12 mb-3 mb-sm-0">
                                    <label><small>Archivo/s subido/s anteriormente:</small></label>
                                        <ul>
                                            <li class="mb-3" v-for="file in data.archivos_existentes" :key="file.id" >
                                                <a class="card-img-top" :href="route + file.url" target="_blank">{{ file.url.split("/")[file.url.split("/").length - 1] }}</a>
                                                <button v-if="action == 'edit'" class="btn btn-danger btn-user ml-3" type="button"
                                                    @click="eliminarArchivo(file)"><i class="fas fa-trash-alt"></i> Eliminar</button>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            <div v-if="action != 'see'" class="form-group">
                                <div  class="form-group">
                                    <label class="col-md-12 mb-0">Añadir archivos</label>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-xl-4 col-lg-4">
                                        <input class="form-control pl-0 form-control-line" type="file" ref="file" @change="obtenerArchivo()" multiple>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 d-flex d-inline">
                                    <router-link v-if="(data.categoria_id != '')" :to="{name: 'ver-estudios', params: {users_persona_id: data.users_persona_id, categoria_id: data.categoria_id}}" class="btn btn-secondary mx-auto mx-md-0 text-white text-decoration-none">Volver</router-link>
                                    <router-link v-else :to="{name: 'estudios', params: {users_persona_id: data.users_persona_id}}" class="btn btn-secondary mx-auto mx-md-0 text-white text-decoration-none">Volver</router-link>
                                    <button v-if="action != 'see'" class="btn btn-success text-white ml-2" :disabled="submit_disabled">{{ submit_button }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal :modal="modal" @eliminar="eliminar(id)"></DeleteModal>
    </div>
</template>
<script>
import { API_ROOT_ROUTE } from '@/environment';
import { emptyFields, actualDate } from '@/helpers/functions'
import { getCategorias } from "./../../services/categoria.service";
import { getEstudio, crearEstudio, editarEstudio, eliminarEstudio } from "./../../services/estudio.service";
import { getArchivos } from "./../../services/archivo.service";
import { getPerfiles } from "./../../services/user.service";
import { getUserData } from "./../../services/user.service";
import DeleteModal from '../layouts/DeleteModal.vue'
import Vue3TagsInput from 'vue3-tags-input';
  
  export default {
    name: "estudio-form-component",
    props: {
        action: String,
        id: [Number, String],
        users_persona_id: {
            type: [ Number, String ],
            required: false,
            default: ''
        },
        categoria_id: {
            type: [ Number, String ],
            required: false,
            default: ''
        }
    },
    data() {
        return {
            data: {
                id: '',
                users_persona_id: this.users_persona_id,
                nombre: '',
                categoria_id: (this.categoria_id) ? this.categoria_id : '',
                tags: '',
                selected_tags: '',
                lugar: '',
                motivo: '',
                fecha: '',
                hora: '',
                archivos: [],
                archivos_existentes: [],
            },
            route: API_ROOT_ROUTE,
            show: false,
            url: '',
            persona_actual: getUserData().persona_id,
            max: actualDate('date'),
            personas: [],
            categorias: [],
            tags: [],
            modal: {
                title: 'Eliminar estudio',
                message: '¿Desea eliminar de manera definitiva este estudio?',
                id: this.id
            },
            disabled: (this.action == 'see') ? true : false,
            submit_button: '',
            submit_disabled: ''
        }
    },
    components: {
        Vue3TagsInput,
        DeleteModal
    },
    mounted() {
        this.submit_button = 'Guardar';
        this.submit_disabled = false;
    },
    created() {
        this.getPerfiles()
        this.getCategorias()

        if(this.action != 'add'){
            this.getEstudio(this.id)
        }
    },
    watch: {
        action: function () {
            this.disabled = (this.action == 'see') ? true : false
        },
        id: function () {
            
        },
        users_persona_id: function () {
            
        },
        categoria_id: function () {
            
        }
    },
    methods: {
        obtenerArchivo() {
            this.data.archivos = [];
            for (let index = 0; index < this.$refs.file.files.length; index++) {
                const element = this.$refs.file.files[index]
                this.data.archivos.push(element);
                
            }
        },
        async getPerfiles(){
            const response = await getPerfiles();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                response.data.forEach(perfil => {
                    this.personas.push({
                        id: perfil.users_persona_id,
                        nombre: perfil.nombre,
                        apellido: perfil.apellido
                    });
                });
                this.personas.sort();
            }
        },
        async getCategorias(){
            const response = await getCategorias();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.categorias = response.data;
            }
        },
        async getEstudio(id){
            const response = await getEstudio(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.id = response.data.id
                this.data.users_persona_id = response.data.users_persona_id
                this.data.nombre = response.data.nombre
                this.data.categoria_id = response.data.categoria_id
                this.tags = (response.data.tags != null) ? response.data.tags.split(",") : []
                this.data.fecha = response.data.fecha
                this.data.hora = response.data.hora
                this.data.lugar = response.data.lugar
                this.data.motivo = response.data.motivo

                this.getArchivos(response.data.id);
            }
        },
        async getArchivos(id) {
            const response = await getArchivos(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.archivos_existentes = response.data
            }
        },
        handleChangeTag(tags) {
            this.data.tags = tags.join(",");
        },
        async send(data) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(this.action == 'add'){
                const response = await crearEstudio(data);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Estudio creado con éxito!")
                    this.$router.push({name: "ver-estudios", params: {users_persona_id: data.users_persona_id, categoria_id: data.categoria_id}})
                    this.data = emptyFields(data);
                }
            } else if(this.action == 'edit'){
                const response = await editarEstudio(data, this.id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Estudio editado con éxito!")
                    this.$router.push({name:'ver-estudio', params: {action: 'see', id: this.id}})
                }
            }
        },
        async eliminar(id){
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(id != '' && id != undefined){
                const response = await eliminarEstudio(id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$router.push({name: "ver-estudios", params: {users_persona_id: this.data.users_persona_id, categoria_id: this.data.categoria_id}})
                    this.$toast.success("¡Estudio eliminado con éxito!")
                }
            }
        },
        eliminarArchivo(img){
            var idx = this.data.archivos_existentes.indexOf(img);
            if (idx > -1) {
                this.data.archivos_existentes.splice(idx, 1);
            }
            //this.show = !this.show;
            this.$toast.error("¡Archivo eliminado con éxito!");
        }
    }
  }
  </script>