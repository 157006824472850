import HTTP from "../environment";

export const getEstudios = (categoria_id, users_persona_id) => {
    return new Promise(resolve => {
        HTTP
            .get('estudios/' + users_persona_id + '/' + categoria_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getEstudio = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const eliminarEstudio = (id) => {
    return new Promise(resolve => {
        HTTP
            .delete('estudio/' + id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateEstudio = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const crearEstudio = (formData) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return new Promise(resolve => {
        HTTP
            .post('estudio', formData, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const editarEstudio = (data, id) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return new Promise(resolve => {
        HTTP
            .post('estudio/editar/' + id, data, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const shareEstudio = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/compartir/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const verEstudio = (token) => {
    return new Promise(resolve => {
        HTTP
            .get('estudio/ver/' + token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}