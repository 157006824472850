<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <Dashboard></Dashboard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Dashboard from './Dashboard.vue';

export default {
    name: "home-component",
    props: {

    },
    components: {
        Dashboard
    },
    data() {
        return {
            data: {

            }
        }
    },
    created(){
        
    },
    methods: {

    }
}
</script>