<template>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12">
                <router-link v-if="action == 'see'" :to="{name:'editar-perfil', params: {action: 'edit', id: id}}" class="btn btn-secondary text-white"><i class="mdi mdi-table-edit"></i> Editar perfil</router-link>
                <button v-if="action == 'see'" type="button" class="btn btn-danger text-white ml-2 float-right" data-toggle="modal" data-target="#exampleModal"><i class="mdi mdi-delete"></i> Eliminar perfil</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-material" method="POST" @submit.prevent="send(data)">
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Nombre</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <input type="text" placeholder="Johnathan" class="form-control pl-0 form-control-line" v-model="data.nombre" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Apellido</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <input type="text" placeholder="Doe" class="form-control pl-0 form-control-line" v-model="data.apellido" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Documento</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <input type="number" placeholder="38000000" class="form-control pl-0 form-control-line" v-model="data.documento" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Fecha de nacimiento</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"> <input type="date" class="form-control pl-0 form-control-line" v-model="data.fecha_nacimiento" :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Teléfono</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="text" placeholder="456 7890" class="form-control pl-0 form-control-line" v-model="data.telefono" :disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Celular</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="text" placeholder="123 456 7890" class="form-control pl-0 form-control-line" v-model="data.celular" :disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Domicilio</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="text" placeholder="Calle 123" class="form-control pl-0 form-control-line" v-model="data.domicilio" :disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 d-flex d-inline">
                                    <router-link :to="{name: 'perfiles'}" class="btn btn-secondary mx-auto mx-md-0 text-white text-decoration-none">Volver</router-link>
                                    <button v-if="action != 'see'" class="btn btn-success text-white ml-2" :disabled="submit_disabled">{{ submit_button }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal :modal="modal" @eliminar="eliminar(id)"></DeleteModal>
    </div>
</template>
<script>
import { emptyFields } from '@/helpers/functions'
import { crearPerfil, getPerfil, editarPerfil, eliminarPerfil } from "./../../services/user.service";
import DeleteModal from '../layouts/DeleteModal.vue'

export default {
    name: "perfil-form-component",
    props: {
        id: Number,
        action: String
    },
    components: {
        DeleteModal
    },
    data() {
        return {
            data: {
                id: '',
                nombre: '',
                apellido: '',
                documento: '',
                fecha_nacimiento: '',
                domicilio: '',
                celular: '',
                telefono: '',
                permiso: 'escritura'
            },
            disabled: (this.action == 'see') ? true : false,
            submit_button: '',
            submit_disabled: '',
            modal: {
                title: 'Eliminar perfil',
                message: '¿Desea eliminar de manera definitiva este perfil?',
                id: this.id
            }
        }
    },
    mounted() {
        this.submit_button = 'Guardar';
        this.submit_disabled = false;
    },
    created() {
        if(this.action != 'add'){
            this.getPerfil(this.id);
        }
    },
    watch: {
        action: function () {
            this.disabled = (this.action == 'see') ? true : false
        },
        id: function() {

        }
    },
    methods: {
        async getPerfil(id){
            const response = await getPerfil(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.id = response.data.id
                this.data.nombre = response.data.nombre
                this.data.apellido = response.data.apellido
                this.data.documento = response.data.documento
                this.data.fecha_nacimiento = response.data.fecha_nacimiento
                this.data.domicilio = response.data.domicilio
                this.data.celular = response.data.celular
                this.data.telefono = response.data.telefono
            }
        },
        async send(data) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(this.action == 'add'){
                const response = await crearPerfil(data);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.data = emptyFields(data);
                    this.$toast.success("¡Perfil creado con éxito!")
                }
            } else if(this.action == 'edit') {
                const response = await editarPerfil(data, this.id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Perfil editado con éxito!")
                }
            }
            
        },
        async eliminar(id){
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(id != '' && id != undefined){
                const response = await eliminarPerfil(id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Perfil eliminado con éxito!")
                    this.$router.push({name: "perfiles"})
                }
            }
        }
    }
}
</script>