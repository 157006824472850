<template>
    <div class="container-fluid">
        <div v-if="turnos.length > 0" class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table user-table">
                                <thead>
                                    <tr>
                                        <th class="border-top-0">#</th>
                                        <th class="border-top-0">Fecha</th>
                                        <th class="border-top-0">Hora</th>
                                        <th class="border-top-0">Profesional</th>
                                        <th class="border-top-0">Lugar</th>
                                        <th class="border-top-0">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(turno) in turnos" :key="turno.id">
                                        <td>{{ turno.codigo }}</td>
                                        <td>{{ turno.fecha }}</td>
                                        <td>{{ turno.hora }}</td>
                                        <td>{{ turno.profesional }}</td>
                                        <td>{{ turno.lugar }}</td>
                                        <td>
                                            <router-link :to="{name:'ver-turno', params: {action: 'see', id: turno.id}}" class="btn btn-primary ml-md-2 ml-xs-0 text-white"><i class="mdi mdi-eye"></i> Ver turno</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p><i class="mdi mdi-alert-outline"></i> No se encontraron turnos.</p> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getStringDate } from "@/helpers/functions"
import { getTurnos } from "./../../services/turno.service";
  
  export default {
    name: "listado-turnos-component",
    props: {
        users_persona_id: Number
    },
    data() {
        return {
            data: {
                
            },
            turnos: []
        }
    },
    mounted() {
        
    },
    created() {
        this.getTurnos(this.users_persona_id)
    },
    watch: {
        users_persona_id: function () {
            this.getTurnos(this.users_persona_id)
        }
    },
    methods: {
        async getTurnos(users_persona_id){
            const response = await getTurnos(users_persona_id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.turnos = response.data;

                this.turnos.forEach(evento => {
                    evento.fecha = getStringDate(evento.fecha)
                })
            }
        },
    }
}
</script>