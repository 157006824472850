<template>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12">
                <router-link v-if="action == 'see'" :to="{name:'editar-turno', params: {action: 'edit', id: id}}"
                    class="btn btn-secondary text-white"><i class="mdi mdi-table-edit"></i> Editar turno</router-link>
                <button v-if="action == 'see'" type="button" class="btn btn-danger text-white ml-2 float-right"
                    data-toggle="modal" data-target="#exampleModal"><i class="mdi mdi-delete"></i> Eliminar
                    turno</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-material" method="POST" @submit.prevent="send(data)"
                            enctype="multipart/form-data">
                            <div class="form-group">
                                <label class="col-md-12 mb-0"><strong>Propietario del turno</strong></label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <select class="form-control pl-0 form-control-line" v-model="data.users_persona_id"
                                        :disabled="(action != 'add') ? true : disabled" required>
                                        <option v-for="(persona) in personas" :key="persona.id" :value="persona.id"
                                            :selected="(persona_actual == persona.id) ? true : false">
                                            {{ (persona_actual == persona.id) ? persona.nombre + ' ' + persona.apellido + '(Yo)': persona.nombre + ' ' + persona.apellido }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Código del turno</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <input type="text" placeholder="ABCD12334"
                                        class="form-control pl-0 form-control-line" v-model="data.codigo"
                                        :disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Fecha del turno</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <input type="date" class="form-control pl-0 form-control-line" v-model="data.fecha"
                                        :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Hora del turno</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <input type="time" class="form-control pl-0 form-control-line" v-model="data.hora"
                                        :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Nombre del profesional</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <input type="text" placeholder="Dr. Juan Pérez"
                                        class="form-control pl-0 form-control-line" v-model="data.profesional"
                                        :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Lugar</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-3">
                                    <input type="text" placeholder="Sanatorio 9 de Julio"
                                        class="form-control pl-0 form-control-line" v-model="data.lugar"
                                        :disabled="disabled" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Estado</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <select class="form-control pl-0 form-control-line" v-model="data.estado" :disabled="disabled" required>
                                        <option value="">Seleccione</option>
                                        <option value="pendiente">Pendiente</option>
                                        <option value="confirmado">Confirmado</option>
                                        <option value="cancelado">Cancelado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 d-flex d-inline">
                                    <router-link :to="{name: 'turnos'}"
                                        class="btn btn-secondary mx-auto mx-md-0 text-white text-decoration-none">Volver
                                    </router-link>
                                    <button v-if="action != 'see'" class="btn btn-success text-white ml-2"
                                        :disabled="submit_disabled">{{ submit_button }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal :modal="modal" @eliminar="eliminar(id)"></DeleteModal>
    </div>
</template>
<script>
import { emptyFields, actualDate } from '@/helpers/functions'
import { getTurno, crearTurno, editarTurno, eliminarTurno } from "./../../services/turno.service";
import { getPerfiles } from "./../../services/user.service";
import { getUserData } from "./../../services/user.service";
import DeleteModal from '../layouts/DeleteModal.vue'

export default {
    name: "turno-form-component",
    props: {
        action: String,
        id: [Number, String],
        users_persona_id: {
            type: [ Number, String ],
            required: false,
            default: ''
        }
    },
    data() {
        return {
            data: {
                id: '',
                users_persona_id: this.users_persona_id,
                codigo: '',
                fecha: '',
                hora: '',
                lugar: '',
                profesional: '',
                estado: 'confirmado'
            },
            persona_actual: getUserData().persona_id,
            max: actualDate('date'),
            personas: [],
            modal: {
                title: 'Eliminar turno',
                message: '¿Desea eliminar de manera definitiva este turno?',
                id: this.id
            },
            disabled: (this.action == 'see') ? true : false,
            submit_button: '',
            submit_disabled: ''
        }
    },
    components: {
        DeleteModal
    },
    mounted() {
        this.submit_button = 'Guardar';
        this.submit_disabled = false;
    },
    created() {
        this.getPerfiles()

        if(this.action != 'add'){
            this.getTurno(this.id)
        }
    },
    watch: {
        action: function () {
            this.disabled = (this.action == 'see') ? true : false
        },
        id: function () {
            
        },
        users_persona_id: function () {
            
        }
    },
    methods: {
        async getPerfiles(){
            const response = await getPerfiles();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                response.data.forEach(perfil => {
                    this.personas.push({
                        id: perfil.users_persona_id,
                        nombre: perfil.nombre,
                        apellido: perfil.apellido
                    });
                });
                this.personas.sort();
            }
        },
        async getTurno(id){
            const response = await getTurno(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.data.id = response.data.id
                this.data.users_persona_id = response.data.users_persona_id
                this.data.codigo = response.data.codigo
                this.data.fecha = response.data.fecha
                this.data.hora = response.data.hora
                this.data.lugar = response.data.lugar
                this.data.profesional = response.data.profesional
                this.data.estado = response.data.estado
            }
        },
        async send(data) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(this.action == 'add'){
                const response = await crearTurno(data);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Turno creado con éxito!")
                    this.$router.push({name: "ver-turnos", params: {users_persona_id: data.users_persona_id}})
                    this.data = emptyFields(data);
                }
            } else if(this.action == 'edit'){
                const response = await editarTurno(data, this.id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$toast.success("¡Turno editado con éxito!")
                    this.$router.push({name:'ver-turno', params: {action: 'see', id: this.id}})
                }
            }
        },
        async eliminar(id){
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            if(id != '' && id != undefined){
                const response = await eliminarTurno(id);
            
                this.submit_button = 'Guardar';
                this.submit_disabled = false;
                
                if(response.data.errors){
                    this.$toast.error("¡Error! " + response.data.message)
                } else {
                    this.$router.push({name: "ver-turnos", params: {users_persona_id: this.data.users_persona_id}})
                    this.$toast.success("¡Turno eliminado con éxito!")
                }
            }
        },
    }
  }
  </script>