<template>
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
        <div class="navbar-header" data-logobg="skin6">
            <a class="navbar-brand" :href="(path != 'inicio' && path != 'registro' && path != 'login' && path != 'visualizar-estudio') ? '/home' : '#'">
                <span class="logo-text">
                    <img src="/assets/images/logo MiSalud_preview_rev_1.png" alt="homepage" class="dark-logo w-75">
                </span>
            </a>
            <a class="nav-toggler waves-effect waves-light text-white d-block d-md-none" href="javascript:void(0)"><i
                    class="ti-menu ti-close"></i></a>
        </div>
        <div v-if="path != 'inicio' && path != 'registro' && path != 'login' && path != 'visualizar-estudio'" class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
            <ul class="navbar-nav d-lg-none d-md-block ">
                <li class="nav-item">
                    <a class="nav-toggler nav-link waves-effect waves-light text-white " href="javascript:void(0)"><i
                            class="ti-menu ti-close"></i></a>
                </li>
            </ul>
            <ul class="navbar-nav mr-auto mt-md-0 ">
                
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <router-link v-if="(user != null)" class="nav-link dropdown-toggle text-muted waves-effect waves-dark" :to="{name: 'perfiles'}"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img
                            src="/assets/images/users/user.png" alt="user" class="profile-pic m-r-10">{{ user.nombre + ' ' + user.apellido }}</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
import { getUserData } from "./../../services/user.service";

export default {
    name: "navbar-component",
    props: {
        path: String
    },
    data() {
        return {
            data: {
                
            }
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    computed: {
        user: function() {
            return getUserData()
        }
    },
    methods: {
        
    }
}
</script>
<style>
    .navbar-header {
        background-color: white !important;
    }

    a.nav-toggler.waves-effect.waves-light.text-white.d-block.d-md-none{
        color: #36807f !important;
    }
</style>