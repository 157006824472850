export const emptyFields = (data) => {
    try {
        for (const field in data) {
            data[field] = "";
        }
        
        return data;
    } catch (error) {
        return data
    }
}

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getRandomNumber = (min, max) => {
    const rndInt = randomIntFromInterval(min, max)
    return rndInt
}

export const timestampToDate = (unix_timestamp) => {
    var date = new Date(unix_timestamp);

    // Day part from the timestamp
    var day = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }

    // Month part from the timestamp
    var month = date.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }

    // Year part from the timestamp
    var year = date.getFullYear();

    var formattedTime = year + '-' + month + '-' + day;
    
    return formattedTime;
}

export const getISOStringDate = (my_date) => {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(my_date);

    // Day part from the timestamp
    var day = date.getDay();
    if (day < 10) {
        day = '0' + day;
    }

    // Month part from the timestamp
    var month = date.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }

    // Year part from the timestamp
    var year = date.getFullYear();

    // Hours part from the timestamp
    // var hours = date.getHours();

    // // Minutes part from the timestamp
    // var minutes = "0" + date.getMinutes();

    // // Seconds part from the timestamp
    // var seconds = "0" + date.getSeconds();
    
    // Will display time in 10:30:23 format
    var formattedTime = year + '-' + month + '-' + day;
    //var date = new Date(my_date);

    return formattedTime;
}

export const getActualDate = () => {
    var date = new Date();
    var dia = '';
    var mes = '';
    if (date.getDate() < 10) {
        dia = '0' + date.getDate();
    } else {
        dia = date.getDate();
    }
    if ((date.getMonth() + 1) < 10) {
        mes = '0' + (date.getMonth() + 1);
    } else {
        mes = (date.getMonth() + 1);
    }
    return date.getFullYear() + "-" + mes + "-" + dia;
}

export const tiene_letras = (texto) => {
    let letras = "abcdefghyjklmnñopqrstuvwxyz";
    let i = 0;
    texto = texto.toLowerCase();
    for (i = 0; i < texto.length; i++) {
        if (letras.indexOf(texto.charAt(i), 0) != -1) {
            return 1;
        }
    }
    return 0;
}

export const tiene_minusculas = (texto) => {
    let letras = "abcdefghyjklmnñopqrstuvwxyz";
    let i = 0;
    for (i = 0; i < texto.length; i++) {
        if (letras.indexOf(texto.charAt(i), 0) != -1) {
            return 1;
        }
    }
    return 0;
}

export const tiene_mayusculas = (texto) => {
    let letras_mayusculas = "ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
    let i = 0;
    for (i = 0; i < texto.length; i++) {
        if (letras_mayusculas.indexOf(texto.charAt(i), 0) != -1) {
            return 1;
        }
    }
    return 0;
}

export const tiene_numeros = (texto) => {
    let numeros = "0123456789";
    let i = 0;
    for (i = 0; i < texto.length; i++) {
        if (numeros.indexOf(texto.charAt(i), 0) != -1) {
            return 1;
        }
    }
    return 0;
}

export const validateForm = (data, required) => {
    var flag = true;
    for (const field in data) {
        for (const required_field in required) {
            if(typeof data[field] != "boolean"){
                if(field == required_field && required[required_field] == 'required' && (data[field].length == 0 || data[field].toString().trim().length === 0)) {
                    flag = field;
                    return flag;
                }
            }
        }
    }
    return true;
}

export const validateInput = (event) => {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
}

export const limitLength = (element, max) => {
    if(element.length > max) {
        element = element.substr(0, max);
    }

    return element;
}

export const minimunChars = (data) => {
    for (const field in data) {
        if(data[field].length < 3){
            return false;
        }
    }

    return true;
}

export const actualDate = (type) => {
    var date = new Date();
    var dia = '';
    var mes = '';
    if (date.getDate() < 10) {
        dia = '0' + date.getDate();
    } else {
        dia = date.getDate();
    }
    if ((date.getMonth() + 1) < 10) {
        mes = '0' + (date.getMonth() + 1);
    } else {
        mes = (date.getMonth() + 1);
    }

    var hora = date.getHours() + ':' + date.getMinutes() + ':00';

    var new_date;

    switch (type) {
        case 'date':
            new_date = date.getFullYear() + "-" + mes + "-" + dia;
            break;

        case 'time':
            new_date = hora;
            break;
    
        case 'datetime':
            new_date = date.getFullYear() + "-" + mes + "-" + dia + 'T' + hora;
            break;
    }

    return new_date;
}

export const getStringDate = (date) => {
    const monthNames = [
        "Ene", 
        "Feb", 
        "Mar", 
        "Abr", 
        "May", 
        "Jun",
        "Jul", 
        "Ago", 
        "Sep", 
        "Oct", 
        "Nov", 
        "Dic"
    ];
    var new_date = new Date(date);
    return new_date.getDate() + ' de ' + monthNames[new_date.getMonth()] + ' de ' + new_date.getFullYear();
}