import HTTP from "../environment";

export const middleware = () => {
    if(localStorage.getItem('misalud') !== null){
        return true;
    }else{
        return false;
    }
}

export const registro = (formData) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return new Promise(resolve => {
        HTTP
            .post('auth/registrar', formData, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                return error
            });
    });
}

export const login = (formData) => {
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return new Promise(resolve => {
        HTTP
            .post('auth/login', formData, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                return error
            });
    });
}

export const logout = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('auth/logout/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                return error
            });
    });
}

export const setToken = (token) => {
    localStorage.setItem("misalud", token);
    return;
}

export const setUserData = (data) => {
    localStorage.setItem("user", JSON.stringify({
        id: data.id,
        nombre: data.nombre,
        apellido: data.apellido,
        email: data.email,
        documento: data.documento,
        persona_id: data.persona_id,
        users_persona_id: data.users_persona_id,
        permission: data.permiso
    }));
    return;
}

export const removeToken = () => {
    localStorage.removeItem("misalud");
    return;
}

export const removeUserData = () => {
    localStorage.removeItem("user");
    return;
}