<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Eventos</h4>
                        <div class="row">
                            <div class="col-xs-12 col-sm-3 col-md-4">
                                <router-link :to="{name: 'agregar-evento', params: {action: 'add'}}" class="btn btn-success mx-auto mx-md-0 text-white mb-3"><i class="mdi mdi-calendar-clock"></i> Agregar evento</router-link>
                            </div>
                            <div class="col-xs-12 col-sm-9 col-md-8">
                                <select class="form-control col-xs-12 col-sm-9 col-md-4 col-xl-3 col-lg-3 pl-0 form-control-line float-right" v-model="data.users_persona_id">
                                    <option v-for="(persona) in personas" :key="persona.id" :value="persona.id" :selected="(persona_actual == persona.id) ? true : false">{{ (persona_actual == persona.id) ? persona.nombre + ' ' + persona.apellido + ' (Yo)': persona.nombre + ' ' + persona.apellido }}</option>
                                </select>
                            </div>
                        </div>
                        <ListadoEventos :users_persona_id="data.users_persona_id"></ListadoEventos>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ListadoEventos from './ListadoEventos.vue';  
import { getPerfiles } from "./../../services/user.service";
import { getUserData } from "./../../services/user.service";

  export default {
    name: "eventos-component",
    props: {
  
    },
    components: {
        ListadoEventos
    },
    data() {
        return {
            data: {
                users_persona_id: (this.users_persona_id != '' && this.users_persona_id != undefined) ? this.users_persona_id : parseInt(getUserData().users_persona_id),
            },
            persona_actual: getUserData().users_persona_id,
            personas: [],
        }
    },
    mounted() {
        
    },
    created() {
        this.getPerfiles()
    },
    methods: {
        async getPerfiles(){
            const response = await getPerfiles();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                response.data.forEach(perfil => {
                    this.personas.push({
                        id: perfil.users_persona_id,
                        nombre: perfil.nombre,
                        apellido: perfil.apellido
                    });
                });
                this.personas.sort();
            }
        },
    }
  }
</script>