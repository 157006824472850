import HTTP from "../environment";

export const getComentarios = (estudio_id) => {
    return new Promise(resolve => {
        HTTP
            .get('comentarios/' + estudio_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getComentario = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('comentario/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const deleteComentario = (comentario) => {
    return new Promise(resolve => {
        HTTP
            .delete('comentario/' + comentario.id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateComentario = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('comentario/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const crearComentario = (formData) => {
    return new Promise(resolve => {
        HTTP
            .post('comentario', formData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const updateComentario = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('comentario/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}