<template>
    <footer :class="(path == 'inicio'|| path == 'login' || path == 'visualizar-estudio') ? 'footer text-center' : 'footer'" :style="(path == 'inicio'|| path == 'login' || path == 'visualizar-estudio') ? 'bottom: 0; position: absolute; width: 100%;' : ''"> © {{ year }} Desarrollado por MiSalud</footer>
</template>
<script>

export default {
    name: "footer-component",
    props: {
        path: String
    },
    data() {
        return {
            year: ''
        }
    },
    mounted() {
        
    },
    created() {
        this.getActualYear()
    },
    methods: {
        getActualYear(){
            var date = new Date;

            this.year = date.getFullYear();
        }
    }
}
</script>