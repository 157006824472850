<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Mis Estudios{{ (categoria.nombre != '') ? ': ' + categoria.nombre : '' }}</h4>
                        <div class="row">
                            <div class="col-xs-12 col-sm-3 col-md-4">
                                <router-link :to="{name: 'agregar-estudio', params: {action: 'add', users_persona_id: data.users_persona_id, categoria_id: (categoria_id != undefined && categoria_id != null) ? categoria_id : null}}" class="btn btn-success mx-auto mx-md-0 text-white mb-3"><i class="mdi mdi-medical-bag"></i> Agregar estudio</router-link>
                            </div>
                            <div class="col-xs-12 col-sm-9 col-md-8">
                                <select class="form-control col-xs-12 col-sm-9 col-md-4 col-xl-3 col-lg-3 pl-0 form-control-line float-right" v-model="data.users_persona_id">
                                    <option v-for="(persona) in personas" :key="persona.id" :value="persona.id" :selected="(persona_actual == persona.id) ? true : false">{{ (persona_actual == persona.id) ? persona.nombre + ' ' + persona.apellido + ' (Yo)': persona.nombre + ' ' + persona.apellido }}</option>
                                </select>
                            </div>
                        </div>
                        <ListadoCategorias v-if="categoria_id == undefined" :users_persona_id="data.users_persona_id"></ListadoCategorias>
                        <ListadoEstudios v-else :categoria_id="categoria_id" :users_persona_id="data.users_persona_id"></ListadoEstudios>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getCategoria } from '../../services/categoria.service';
import ListadoCategorias from '../categorias/ListadoCategorias.vue';
import ListadoEstudios from '../estudios/ListadoEstudios.vue';
import { getPerfiles } from "./../../services/user.service";
import { getUserData } from "./../../services/user.service";
  
export default {
    name: "estudios-component",
    props: {
        users_persona_id: [Number, String],
        categoria_id: [Number, String]
    },
    data() {
        return {
            data: {
                users_persona_id: (this.users_persona_id != '' && this.users_persona_id != undefined) ? this.users_persona_id : parseInt(getUserData().users_persona_id),
            },
            persona_actual: getUserData().users_persona_id,
            personas: [],
            categoria: {
                nombre: ''
            }
        }
    },
    components: {
        ListadoCategorias,
        ListadoEstudios
    },
    mounted() {
        
    },
    created() {
        this.getPerfiles()
        if(this.categoria_id != '' && this.categoria_id != undefined){
            this.getCategoria(this.categoria_id)
        }
    },
    watch:{
        categoria_id: function () {
            if(this.categoria_id != '' && this.categoria_id != undefined){
                this.getCategoria(this.categoria_id)
            } else {
                this.categoria.nombre = ''
            }
        }
    },
    methods: {
        async getPerfiles(){
            const response = await getPerfiles();

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                response.data.forEach(perfil => {
                    this.personas.push({
                        id: perfil.users_persona_id,
                        nombre: perfil.nombre,
                        apellido: perfil.apellido
                    });
                });
                this.personas.sort();
            }
        },
        async getCategoria(id) {
            const response = await getCategoria(id);

            if(response.data.errors){
                this.$toast.error("¡Error! " + response.data.message)
            } else {
                this.categoria.nombre = response.data.nombre
            }
        }
    }
}
</script>