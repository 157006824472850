import HTTP from "../environment";

export const getEventos = (users_persona_id) => {
    return new Promise(resolve => {
        HTTP
            .get('eventos/' + users_persona_id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const getEvento = (id) => {
    return new Promise(resolve => {
        HTTP
            .get('evento/' + id)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const eliminarEvento = (id) => {
    return new Promise(resolve => {
        HTTP
            .delete('evento/' + id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const validateEvento = (name) => {
    return new Promise(resolve => {
        HTTP
            .get('evento/name/' + name)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            })
    })
}

export const crearEvento = (formData) => {
    return new Promise(resolve => {
        HTTP
            .post('evento', formData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}

export const editarEvento = (data, id) => {
    return new Promise(resolve => {
        HTTP
            .put('evento/' + id, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                resolve({
                    errors: true,
                    message: error.message,
                    status: error.code
                })
            });
    });
}