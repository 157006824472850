<template>
  <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <header class="topbar" data-navbarbg="skin6">
      <Navbar :path="route"></Navbar>
    </header>
    <Sidebar v-if="route != 'registro' && route != 'inicio' && route != 'login' && route != 'visualizar-estudio'"></Sidebar>
    <div :class="(route != 'registro' && route != 'inicio' && route != 'login' && route != 'visualizar-estudio') ? 'page-wrapper' : ''">
      <Breadcrumb v-if="route != 'registro' && route != 'inicio' && route != 'login' && route != 'visualizar-estudio'"></Breadcrumb>
      <router-view></router-view>
      <Footer :path="route"></Footer>
    </div>
  </div>
</template>

<script>
import Navbar from './components/layouts/Navbar.vue'
import Sidebar from './components/layouts/Sidebar.vue'
import Breadcrumb from './components/layouts/Breadcrumb.vue'
import Footer from './components/layouts/Footer.vue'
import { useRoute } from 'vue-router';


export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  props: {
    auth: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Navbar,
    Sidebar,
    Breadcrumb,
    Footer
  },
  mounted(){

  },
  created() {
    
  },
  computed: {
    route: function (){
      return useRoute().name
    } 
  }
}
</script>