<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ modal.title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{ modal.message }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="eliminar()">Eliminar</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  
  export default {
    name: "delete-modal-component",
    props: {
        modal: Object
    },
    data() {
        return {
            data: {
                
            }
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    methods: {
        eliminar(){
            this.$emit("eliminar", this.modal.id)
        }
    }
  }
  </script>