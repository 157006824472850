<template>
    <aside class="left-sidebar" data-sidebarbg="skin6">
        <div class="scroll-sidebar">
            <nav class="sidebar-nav">
                <ul id="sidebarnav">
                    <li class="sidebar-item"> <router-link class="sidebar-link waves-effect waves-dark sidebar-link"
                            :to="{name: 'home'}" aria-expanded="false"><i class="mdi mr-2 mdi-gauge"></i><span
                                class="hide-menu">Dashboard</span></router-link></li>
                    <li class="sidebar-item"> <router-link class="sidebar-link waves-effect waves-dark sidebar-link"
                            :to="{name: 'perfiles'}" aria-expanded="false">
                            <i class="mdi mr-2 mdi-account-check"></i><span class="hide-menu">Perfiles</span></router-link>
                    </li>
                    <li class="sidebar-item"> <router-link class="sidebar-link waves-effect waves-dark sidebar-link"
                        :to="{name: 'estudios'}" aria-expanded="false"><i class="mdi mr-2 mdi-medical-bag"></i><span
                                class="hide-menu">Estudios</span></router-link></li>
                    <li class="sidebar-item"> <router-link class="sidebar-link waves-effect waves-dark sidebar-link"
                            :to="{name: 'eventos'}" aria-expanded="false"><i class="mdi mr-2 mdi-calendar-clock"></i><span
                                class="hide-menu">Eventos</span></router-link></li>
                    <li class="sidebar-item"> <router-link class="sidebar-link waves-effect waves-dark sidebar-link"
                            :to="{name: 'turnos'}" aria-expanded="false"><i class="mdi mr-2 mdi-timer"></i><span
                                class="hide-menu">Turnos</span></router-link></li>
                </ul>

            </nav>
        </div>
        <div class="sidebar-footer">
            <div class="row">
                <div class="col-4 link-wrap">
                    <router-link to="" class="link" data-toggle="tooltip" title="" data-original-title="Settings"><i
                            class="ti-settings"></i></router-link>
                </div>
                <div class="col-4 link-wrap">
                    <router-link to="" class="link" data-toggle="tooltip" title="" data-original-title="Email"><i
                            class="mdi mdi-gmail"></i></router-link>
                </div>
                <div class="col-4 link-wrap">
                    <router-link to="" class="link" data-toggle="tooltip" title="" data-original-title="Logout" @click="logout()"><i
                            class="mdi mdi-power"></i></router-link>
                </div>
            </div>
        </div>
    </aside>
</template>
<script>
import { getUserData } from '../../services/user.service';
import { logout, removeToken, removeUserData } from "./../../services/auth.service";

export default {
    name: "sidebar-component",
    props: {

    },
    data() {
        return {
            data: {
                
            }
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    methods: {
        async logout(){
            const user = getUserData();

            const response = await logout(user.id);
            
            if(response.data.errors){
                this.$toast.error("¡Error! " + response.message)
            } else {                    
                removeToken();
                removeUserData();

                this.$toast.success("¡Hasta luego!")
                this.$router.push({name: "login"})
            }
        }
    }
}
</script>
<style>
    aside {
        background:#121f3d !important;
    }

    #sidebarnav {
        background-color: #121f3d !important;
    }

    #sidebarnav>li>a {
        color: #fff !important;
    }

    #sidebarnav>li>a>i {
        color: #fff !important;
    }
</style>