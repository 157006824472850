<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card mt-3">
                    <div class="card-body">
                        <div class="col-12 text-center">
                            <h4 class="card-title">Complete el siguiente formulario para registrarse en MiSalud</h4>
                        </div>
                        <form class="form-horizontal form-material" method="POST" @submit.prevent="send(data)">
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Nombre</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <input type="text" placeholder="Johnathan" class="form-control pl-0 form-control-line" v-model="data.nombre" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Apellido</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <input type="text" placeholder="Doe" class="form-control pl-0 form-control-line" v-model="data.apellido" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Documento</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
                                    <input type="number" placeholder="38000000" class="form-control pl-0 form-control-line" v-model="data.documento" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Fecha de nacimiento</label>
                                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"> <input type="date" class="form-control pl-0 form-control-line" v-model="data.fecha_nacimiento" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Teléfono</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="text" placeholder="456 7890" class="form-control pl-0 form-control-line" v-model="data.telefono">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Celular</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="text" placeholder="123 456 7890" class="form-control pl-0 form-control-line" v-model="data.celular">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Domicilio</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="text" placeholder="Calle 123" class="form-control pl-0 form-control-line" v-model="data.domicilio">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="example-email" class="col-md-12">Email</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="email" placeholder="johnathan@admin.com" class="form-control pl-0 form-control-line" name="example-email" id="example-email" v-model="data.email">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Contraseña</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="password" ref="password" class="form-control pl-0 form-control-line" v-model="data.password" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 mb-0">Repetir contraseña</label>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <input type="password" class="form-control pl-0 form-control-line" v-model="data.repeat_password" @change="confirmPassword()" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-success mx-auto mx-md-0 text-white" :disabled="submit_disabled">{{ submit_button }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { emptyFields } from '@/helpers/functions'
import { registro } from "./../../services/auth.service";

export default {
    name: "registro-component",
    props: {

    },
    data() {
        return {
            data: {
                nombre: '',
                apellido: '',
                documento: '',
                fecha_nacimiento: '',
                domicilio: '',
                celular: '',
                telefono: '',
                imagen: '',
                permiso: '',
                estado: '',
                email: '',
                password: '',
                repeat_password: ''
            },
            submit_button: '',
            submit_disabled: '',
            message: ''
        }
    },
    mounted() {
        this.submit_button = 'Registrar';
        this.submit_disabled = false;
    },
    created() {
        
    },
    methods: {
        async send(data) {
            this.submit_button = 'Cargando..';
            this.submit_disabled = true;

            const response = await registro(data);
            
            this.submit_button = 'Registrar';
            this.submit_disabled = false;
            
            if(response.errors){
                this.$toast.error("¡Error! " + response.error)
            } else {
                this.data = emptyFields(data);
                this.$toast.success("¡Usuario creado con éxito!")
                this.$router.push({name: "login"})
            }
        },
        confirmPassword() {
            if (this.data.password != this.data.repeat_password) {
                this.$toast.error("¡Error! Las contraseñas no coinciden, por favor ingreselas nuevamente.");
                this.data.password = "";
                this.data.repeat_password = "";
                this.$refs.password.focus();
            }
        },
    }
}
</script>